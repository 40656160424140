import React from "react";

export const ClientComponent = () => {

    return(
    <section className="parallax-img parallax" id="blog"> 
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3 my-3">
              
                <div id="testimonial-carousal" className="owl-carousel owl-theme testimonial-owl text-center wow fadeIn" data-wow-delay="300ms">
                 
                    <div className="item">
                        <div className="icon-quotes mb-4">
                            <i className="fas fa-quote-right"></i>
                        </div>
                        <div className="description">
                            <p className="text-white paragraph">Grupo Dportenis cuenta con más de 300 tiendas a nivel nacional (México) y ARA Consultoría y Soluciones elaboró la aplicación Web de facturación electrónica. Asimismo, se le brinda el servicio de timbrado. </p>
                        </div>
                        <div className="testimonial-img mt-4">
                            <img src="assets/corporate-agency/images/clientes/Dportenis-intro.png" alt="student img" width={10} />
                        </div>
                        <div className="testimonial-tittle mt-3 mb-3">
                            <h3 className="mb-0 text-white alt-font font-weight-normal font-24">Grupo Dportenis</h3>
                        </div>
                    </div>

                    
                    <div className="item">
                        <div className="icon-quotes mb-4">
                            <i className="fas fa-quote-right"></i>
                        </div>
                        <div className="description">
                            <p className="text-white paragraph">ARA Consultoría y Soluciones se encargó de elaborar la aplicación Web para el pre-registro, de tal forma que a través de un código QR generado con los datos del asistente al evento, se logró agilizar el registro in situ evitando el lago tiempo de espera.</p>
                        </div>
                        <div className="testimonial-img mt-4">
                            <img src="assets/corporate-agency/images/clientes/smm.jpg" alt="student img" />
                        </div>
                        <div className="testimonial-tittle mt-3 mb-3">
                            <h3 className="mb-0 text-white alt-font font-weight-normal font-24">Legendaria Semana de la Moto Mazatlán</h3>
                        </div>
                    </div>

                  
                    <div className="item">
                        <div className="icon-quotes mb-4">
                            <i className="fas fa-quote-right"></i>
                        </div>
                        <div className="description">
                            <p className="text-white paragraph">Se desarrolló una solución para controlar el acceso de vehículos en el fraccionamiento a través de etiquetas RFiD colocadas en los cristales de los automóviles de los colonos.

                            El fraccionamiento cuenta con 5 entradas vehiculares en las cuales está instalado el sistema, cabe mencionar que en promedio entran y salen al dia entre 7 mil y 8 mil vehículos, las casetas trabajan 24x7.</p>
                        </div>
                        <div className="testimonial-img mt-4">
                            <img src="assets/corporate-agency/images/clientes/cid.jpg" alt="student img" />
                        </div>
                        <div className="testimonial-tittle mt-3 mb-3">
                            <h3 className="mb-0 text-white alt-font font-weight-normal font-24">Fraccionamiento El Cid</h3>
                        </div>
                    </div>
                    

                    
                    <div className="item">
                        <div className="icon-quotes mb-4">
                            <i className="fas fa-quote-right"></i>
                        </div>
                        <div className="description">
                            <p className="text-white paragraph">Es un grupo dedicado a la compra-venta de artículos ferreteros, agrícolas e industriales; brinda una diversidad de productos, en el ámbito doméstico, comercial e industrial, satisfaciendo cualquier sector de la población.</p>
                        </div>
                        <div className="testimonial-img mt-4">
                            <img src="assets/corporate-agency/images/clientes/tomaco.png" alt="student img" />
                        </div>
                        <div className="testimonial-tittle mt-3 mb-3">
                            <h3 className="mb-0 text-white alt-font font-weight-normal font-24">Tomaco</h3>
                        </div>
                    </div>

                   
                    
                </div>
            </div>
          </div>
        </div>
    </section>
    );

}