import React, { useState } from "react";
import Swal from "sweetalert2";

export const FormCotact = () =>{

    const [datos, setDatos] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        mensaje: ''
    })

   const handleInputChange = (event:any) => {
        //  console.log(event.target.name)
        //  console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        })
    }

     const enviarDatos = async (event:any) => {
        event.preventDefault()
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(datos)
            }
            const response = await fetch('https://microservice-email-3c7rl.ondigitalocean.app/email', config)
            const res:any = await response.json();
            //console.log(res)
            if (response.ok) {
                //return json
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({
                    icon: 'success',
                    title: `${res.message}`
                  });
                  setDatos({
                      nombre: '',
                      apellido: '',
                      correo: '',
                      telefono: '',
                      mensaje: ''
                  })
                  event.target.reset();
            } else {
                //
            }
        } catch (error) {
            event.target.reset();
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'success',
                title: `Ha ocurrido un error`
              });
              setDatos({
                  nombre: '',
                  apellido: '',
                  correo: '',
                  telefono: '',
                  mensaje: ''
              })
              event.target.reset();
        }
        //const response = await fetch('http://127.0.0.1:5000/email');
        
    }




    return ( 
    <div className="col-12 contact-form-box">
    <form className="contact-form" id="contact-form-data" onSubmit={enviarDatos}>
        <div className="row">
           
            <div className="col-12">
                <div className="col-sm-12 px-0" id="result"></div>
            </div>

            <div className="col-12 col-md-6">
                <div className="form-group">
                    <input className="form-control" type="text" placeholder="Nombre's:"  id="first_name" name="nombre" onChange={handleInputChange} required />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                    <input className="form-control" type="text" placeholder="Apellido's:"  id="last_name" name="apellido" onChange={handleInputChange} required/>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                    <input className="form-control" type="email" placeholder="Correo Electrónico:"  id="email" name="correo"  onChange={handleInputChange} required/>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                    <input className="form-control" type="tel" placeholder="Teléfono:" id="phone" name="telefono" onChange={handleInputChange} required/>
                </div>
            </div>
            <div className="col-12">
                <div className="form-group">
                    <textarea className="form-control" placeholder="Mensaje" id="message" name="mensaje" onChange={handleInputChange}></textarea>
                </div>
            </div>
            <div className="col-12">
                <button type="submit" className="btn btn-medium green-long-btn rounded-pill w-100 btn-pink mt-4 contact_btn" id="submit_btn">Enviar</button>
            </div>
        </div>
    </form>
</div>);
}