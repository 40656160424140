import React, { useState } from "react";
import { FormCotact } from "./formContact";

export const ContactComponent = () => {


    
        

    return(
    <section id="contact" className="contact-sec">
    <div className="container">
       
        <div className="row">
            <div className="col-12 col-md-8 offset-md-2 text-center">
                <p className="text-pink font-weight-200 font-20">Como llegar a nosotros</p>
                <h1 className="main-font font-weight-600 text-white">Mantengámonos en contacto</h1>
            </div>
        </div>

     
        <div className="row">
            <div className="col-12 col-lg-12 contact-details text-md-left">
                <div className="font-15 alt-font text-white text-center text-lg-left">
                ARA no se trata solo de desarollo; es más que eso. Ofrecemos servicios de comunicación integral y somos responsables de nuestro proceso y resultados. Agradecemos a cada cliente y sus proyectos.
                </div>
                <div className="row mt-5 wow fadeIn" data-wow-delay="200ms">
                  
                    <div className="col-12 col-md-6 text-center text-lg-left">
                        <h4 className="main-font text-pink font-16 font-weight-600">Nuestra dirección</h4>
                        <p className="alt-font font-14 text-white mt-3">Ave. Azul Aguamarina No. 1748-C, Fracc. Azul Pacifico, C. P. 82112. </p>
                    </div>
                 
                    <div className="col-12 col-md-6 pt-5 pt-md-0 wow fadeIn text-center text-lg-left" data-wow-delay="400ms">
                        <h4 className="main-font text-pink font-16 font-weight-600">Nuestro Teléfono</h4>
                        <p className="alt-font font-14 text-white mt-3">Movíl : +52 6692 270 8546 </p>
                    </div>
                </div>

                <div className="row mt-5">
                  
                    <div className="col-12 col-md-6 wow fadeIn text-center text-lg-left" data-wow-delay="600ms">
                        <h4 className="main-font text-pink font-16 font-weight-600">Nuestro Correo Electrónico</h4>
                        <p className="alt-font font-14 text-white mt-3">Main Support : abraham@araconsultoria.com</p>
                    </div>
                   
                    <div className="col-12 col-md-6 pt-5 pt-md-0 wow fadeIn text-center text-lg-left" data-wow-delay="800ms">
                        <h4 className="main-font text-pink font-16 font-weight-600">Nuestro Soporte</h4>
                        <p className="alt-font font-14 text-white mt-3">Main Support : ismael@araconsultoria.com</p>
                    </div>
                </div>
            </div>

           <FormCotact></FormCotact>
        </div>
    </div>
</section>
    );
}