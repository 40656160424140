import React from "react";

export const BlogComponent = () =>{ 
    return(
        <section className="half-section p-0 blog blog-bg" id="blog">
    <h2 className="d-none">heading</h2>
    <div className="container-fluid">
        <div className="row align-items-center blog-bg">
            <div className="col-lg-6 col-md-12 p-0 order-1 order-md-2">
                <div className="owl-carousel owl-theme owl-split">
                 
                    <div className="item">
                        <div className="hover-effect">
                            <img alt="blog" src="assets/corporate-agency/images/blog-img1.jpg" className="team-img" />
                        </div>
                    </div>
                  
                    <div className="item">
                        <div className="hover-effect">
                            <img alt="blog" src="assets/corporate-agency/images/blog-img2.jpg" className="team-img" />
                        </div>
                    </div>
                   
                    <div className="item">
                        <div className="hover-effect">
                            <img alt="blog" src="assets/corporate-agency/images/blog-img3.jpg" className="team-img" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-md-12">
                <div className="align-items-center pc-0 px-md-5 skill-box">
                    <div className="main-title mb-5 text-center text-lg-left wow fadeIn" data-wow-delay="300ms">
                        {/* <p className="text-pink font-weight-200 font-20">News & Blogs</p> */}
                        <h1 className="main-font font-weight-600 text-white margin-top">Comprometidos con nuestro CLinetes</h1>
                        <p className="font-15 alt-font font-weight-normal text-grey margin-top">La mejor opción para el desarrollo ideal</p>
                        {/* <a href="corporate-agency/blog.html" className="mt-3 btn btn-medium btn-rounded btn-pink">Read Blog</a> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    );
}