import React from "react";


export const InfoComponent = () => {

    return(
    <section className="about" id="about">
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-6 wow fadeInLeft" data-wow-delay="300ms">
                <p className="text-pink font-weight-200 font-20">Información Básica</p>
                <h1 className="main-font font-weight-600 text-white">Empresa especializada en <span>Desarrollo de Software</span></h1>
            </div>

            <div className="col-12 col-lg-6 m-ipad wow fadeInRight" data-wow-delay="300ms">
                <div className="ml-md-4 pl-md-2 font-weight-200 text-grey font-18">
                    <p>ARA Consultoría y Desarrollos nació en el año de 2016 en Mazatlán, Sinaloa. Ha destacado por ser una empresa que ofrece un servicio de calidad con precios competitivos en diferentes ámbitos como en proyectos con fondos de gobierno, en conjunto (vinculación) con grupos de investigación de universidades y con servicios de consultoría y asesoría en empresas locales y nacionales.</p>
                   
                </div>
            </div>
        </div>
        
        <div className="row about-margin">
            
            <div className="col-md-4 col-sm-12">
                <div className="about-box center-block bg-green wow zoomIn" data-wow-delay="400ms">
                    <div className="about-main-icon pb-4">
                        <i className="fa fa-lightbulb" aria-hidden="true"></i>
                    </div>
                    <h5>Consultoría e <span> ideas para tí.</span></h5>
                </div>
            </div>
           
            <div className="col-md-4 col-sm-12">
                <div className="about-box center-block bg-blue wow zoomIn" data-wow-delay="500ms">
                    <div className="about-main-icon pb-4">
                        <i className="fa fa-briefcase" aria-hidden="true"></i>
                    </div>
                    <h5>Tu negocio es nuestra <span>máxima prioridad.</span></h5>
                </div>
            </div>
         
            <div className="col-md-4 col-sm-12">
                <div className="about-box center-block bg-green wow zoomIn" data-wow-delay="600ms">
                    <div className="about-main-icon pb-4">
                        <i className="fa fa-grin-hearts" aria-hidden="true"></i>
                    </div>
                    <h5>Amamos a nuestros valiosos<span> clientes.</span></h5>
                </div>
            </div>
        </div>
      
        <div className="d-flex justify-content-center wow bounceInLeft" data-wow-delay="300ms">
            <img src="assets/corporate-agency/images/about-img.png" alt="About-Us" />
        </div>
    </div>
</section>
    );

}