import React from "react";

export const PortfolioComponent = () =>{
    return (
    <section id="portfolio" className="padding portfolio">
        <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="title text-center pb-5 wow fadeInUp" data-wow-delay="100ms">
                    <p className="text-pink font-weight-200 font-20">Información básica sobre el trabajo</p>
                    <h1 className="main-font font-weight-600 text-white">Trabajo de diseño creativo</h1>
                    <p className="margin-top font-16 alt-font font-weight-normal text-grey">Expertos en desarrollo multiplataforma</p>
                </div>
                <div className="pointer nav nav-pills mb-4 mb-md-4 d-flex justify-content-center filtering">
                    <span data-filter="*" className="nav-link active">All</span>
                    <span className="nav-link" data-filter=".Agencies">WEB</span>
                    <span className="nav-link" data-filter=".Business">MOVIL</span>
                    {/* <span className="nav-link" data-filter=".Personal">ESCRITORIO</span> */}
                    {/* <span className="nav-link" data-filter=".Medical">Medical</span> */}
                </div>

                <ul className="da-thumbs gallery">
                
                    <li className="items  Agencies">
                        <img src="https://ismael-osuna-portfolio.firebaseapp.com/assets/images/portfolio/events.PNG" alt="img" />
                        <a href="https://ismael-osuna-portfolio.firebaseapp.com/assets/images/portfolio/events.PNG" className="text-center" data-fancybox="images">
                            <div className="overlay">
                                <div>
                                <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <h4 className="">Events by asher</h4>
                                  
                                </div>
                            </div>
                        </a>
                    </li>

                  
                    <li className="items  Agencies">
                        <img src="assets/corporate-agency/images/clientes/miss.PNG" alt="img" />
                        <a href="assets/corporate-agency/images/clientes/miss.PNG" className="text-center" data-fancybox="images">
                            <div className="overlay">
                                <div>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <h4 className="">Mispepper</h4>
                                   
                                </div>
                            </div>
                        </a>
                    </li>
                    
                    <li className="items  Agencies">
                        <img src="assets/corporate-agency/images/clientes/control.PNG" alt="img" />
                        <a href="assets/corporate-agency/images/clientes/control.PNG" className="text-center" data-fancybox="images">
                            <div className="overlay">
                                <div>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <h4 className="">Control37</h4>
                                   
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="items  Agencies">
                        <img src="assets/corporate-agency/images/clientes/vcensus.PNG" alt="img" />
                        <a href="assets/corporate-agency/images/clientes/vcensus.PNG" className="text-center" data-fancybox="images">
                            <div className="overlay">
                                <div>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <h4 className="">vCensus</h4>
                                   
                                </div>
                            </div>
                        </a>
                    </li>



                    <li className="items  Agencies">
                        <img src="assets/corporate-agency/images/clientes/tugo.PNG" alt="img" />
                        <a href="assets/corporate-agency/images/clientes/tugo.PNG" className="text-center" data-fancybox="images">
                            <div className="overlay">
                                <div>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <h4 className="">Tugo Administrador</h4>
                                   
                                </div>
                            </div>
                        </a>
                    </li>

                   
                    <li className="items Business">
                        <img src="assets/corporate-agency/images/clientes/parro1p.png" alt="img" />
                        <a href="assets/corporate-agency/images/clientes/parro2p.png" className="text-center" data-fancybox="images">
                            <div className="overlay">
                                <div>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <h4 className="">St. Joseph Catholic Church</h4>
                                 
                                </div>
                            </div>
                        </a>
                    </li>

                  
                    <li className="items Business">
                        <img src="assets/corporate-agency/images/clientes/tugop1.png" alt="img"/>
                        <a href="assets/corporate-agency/images/clientes/tugop2.png" className="text-center" data-fancybox="images">
                            <div className="overlay">
                                <div>
                                    <div className="search-icon"><i className="fa fa-search"></i></div>
                                    <h4 className="">TuGo</h4>
                                   
                                </div>
                            </div>
                        </a>
                    </li>

                 
                    
                </ul>
                {/* <div className="text-center pt-5">
                    <a href="corporate-agency/standalone.html" className="btn btn-medium btn-rounded btn-pink">View More Portfolio</a>
                </div> */}
            </div>
        </div>
    </div>
   
    <div className="clients">
        <h2 className="d-none">hidden</h2>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    
                    <div className="owl-carousel partners-slider">
                       
                        <div className="logo-item"><img alt="client-logo" src="assets/corporate-agency/images/clientes/calzzapato-logo.jpg"/></div>
                      
                        <div className="logo-item"><img alt="client-logo" src="assets/corporate-agency/images/clientes/cid.jpg" width={20}/></div>
                       
                        <div className="logo-item"><img alt="client-logo" src="assets/corporate-agency/images/clientes/Dportenis-intro.png"/></div>
                       
                        <div className="logo-item"><img alt="client-logo" src="assets/corporate-agency/images/clientes/housika.jpg" width={20}/></div>

                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    );
}