import React from "react";

export const MapComponent = () => {
    return(
        <section className="p-0">
            <div className="row">
                <div className="col-12">
                    <div className="mapouter">
                        <div id="map" className="full-map bg-img-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.163845606612!2d-106.48140188464257!3d23.309813361379966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869f530f2d40b1eb%3A0x9eac62654e1f784!2sAv.%20Ernesto%20Coppel%20Campa%C3%B1a%20201%2C%20Zona%20Nuevo%2C%2082110%20Mazatl%C3%A1n%2C%20Sin.!5e0!3m2!1ses-419!2smx!4v1639089438953!5m2!1ses-419!2smx" width="600" height="450"  loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
</section>
    );
}