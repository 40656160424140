import React from "react";


export const FooterComponent = ()  => {
    return(
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center">
                    
                    <div className="col-12 text-center">
                        <div className="footer-social">
                            <ul className="list-unstyled social-icons social-icons-simple">
                                <li><a className="social-icon wow fadeInUp" ><i className="fab fa-facebook-f" aria-hidden="true"></i> </a> </li>
                                {/* <li><a className="social-icon wow fadeInDown" ><i className="fab fa-twitter" aria-hidden="true"></i> </a> </li>
                                <li><a className="social-icon wow fadeInUp" ><i className="fab fa-google-plus-g" aria-hidden="true"></i> </a> </li>
                                <li><a className="social-icon wow fadeInDown" ><i className="fab fa-linkedin-in" aria-hidden="true"></i> </a> </li>
                                <li><a className="social-icon wow fadeInUp"><i className="fab fa-instagram" aria-hidden="true"></i> </a> </li>
                                <li><a className="social-icon wow fadeInUp" ><i className="fab fa-pinterest-p" aria-hidden="true"></i> </a> </li> */}
                            </ul>
                        </div>
                        
                        <p className="company-about fadeIn text-white">© 2021 Ara Consultoría y Soluciones <a ></a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}