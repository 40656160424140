import React from 'react';
import ReactDOM from 'react-dom';
import { BlogComponent } from './components/blog';
import { ClientComponent } from './components/clients';
import { ContactComponent } from './components/contact';
import { FooterComponent } from './components/footer';
import { HeaderComponent } from './components/header';
import { InfoComponent } from './components/info';
import { MapComponent } from './components/map';
import { PortfolioComponent } from './components/portfolio';
import { SkillsComponent } from './components/skills';
import { SliderComponent } from './components/sliders';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <HeaderComponent></HeaderComponent>
    <SliderComponent></SliderComponent>
    <a href="#about" className="scroll-down link scroll main-font font-15 animate">Scroll Down <i className="fas fa-long-arrow-alt-down"></i></a>
    <InfoComponent></InfoComponent>
    <SkillsComponent></SkillsComponent>
    <PortfolioComponent></PortfolioComponent>
    <ClientComponent></ClientComponent>
    <BlogComponent></BlogComponent>
    <ContactComponent></ContactComponent>
    <MapComponent></MapComponent>
    <FooterComponent></FooterComponent>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// "homepage": "http://localhost/ara/",