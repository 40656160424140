import React from "react";


export const SkillsComponent = () => {
    return(

        <section className="half-section p-0 stats-bg">
    <h2 className="d-none">heading</h2>
    <div className="container-fluid">
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 p-0 order-1 order-md-2">
                <div className="owl-carousel owl-theme owl-split">
                   
                    <div className="item">
                        <div className="hover-effect">
                            <img alt="blog" src="assets/corporate-agency/images/team-img1.jpg" className="team-img"  />
                        </div>
                    </div>
                  
                    <div className="item">
                        <div className="hover-effect">
                            <img alt="blog" src="assets/corporate-agency/images/team-img2.jpg" className="team-img" />
                        </div>
                    </div>
                  
                    <div className="item">
                        <div className="hover-effect">
                            <img alt="blog" src="assets/corporate-agency/images/team-img3.jpg" className="team-img" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-md-12">
                <div className="skill-box">
                    <div className="main-title mb-5 text-center text-lg-left main-title wow fadeIn" data-wow-delay="300ms">
                        <p className="font-weight-200 text-pink font-20">Comprueba nuestras habilidades</p>
                        <h2 className="margin-top main-font font-40 font-weight-normal text-white">Tenemos la mejor creatividad<span className="d-block mt-1">y equipo calificado.</span></h2>
                    </div>
                    <ul className="text-left">
                       
                        <li className="custom-progress text-white">
                            <h6 className="font-16 mb-0 text-capitalize">Consulta de trabajo <span className="float-right"><b className="numscroller">90</b>%</span></h6>

                            <div className="progress">
                                <div className="progress-bar bg-pink" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} ></div>
                            </div>
                        </li>
                       
                        <li className="custom-progress text-white">
                            <h6 className="font-16 mb-0 text-capitalize">Desarrollo<span className="float-right"><b className="numscroller">97</b>%</span></h6>

                            <div className="progress">
                                <div className="progress-bar bg-pink" role="progressbar" aria-valuenow={97} aria-valuemin={0} aria-valuemax={100}>
                                </div>
                            </div>
                        </li>
                      
                        <li className="custom-progress text-white">
                            <h6 className="font-16 mb-0 text-capitalize">Comunicación <span className="float-right"><b className="numscroller">95</b>%</span></h6>

                            <div className="progress">
                                <div className="progress-bar bg-pink" role="progressbar" aria-valuenow={95} aria-valuemin={0} aria-valuemax={100}>
                                </div>
                            </div>
                        </li>
                       
                        <li className="custom-progress mb-0 text-white">
                            <h6 className="font-16 mb-0">Asesoría<span className="float-right"><b className="numscroller">95</b>%</span></h6>

                            <div className="progress">
                                <div className="progress-bar bg-pink" role="progressbar" aria-valuenow={95} aria-valuemin={0} aria-valuemax={100}>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
    );
}