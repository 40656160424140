import React from "react";


export const SliderComponent = () => {

    return (
        <div id="slider-section" className="slider-section">
        <div id="revo_main_wrapper" className="rev_slider_wrapper fullwidthbanner-container m-0 p-0 bg-dark" data-alias="classNameic4export" data-source="gallery">
    
        <div id="vertical-bullets" className="rev_slider fullwidthabanner white vertical-tpb" data-version="5.4.1">
            <ul>
               
                <li data-index="rs-01" data-transition="fade" data-slotamount="default" data-easein="Power100.easeIn" data-easeout="Power100.easeOut" data-masterspeed="2000" data-fsmasterspeed="1500" data-param1="01">
                 

                    <img src="assets/corporate-agency/images/slider1.png"  alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
                    <div className="bg-overlay bg-black opacity-4"></div>
                    
                   
                    <div className="tp-caption tp-resizeme"
                         data-x="['right','center','center','center']" data-hoffset="['0','0','0','0']"
                         data-y="['middle','middle','middle','middle']" data-voffset="['-30','-30','-10','-10']"
                         data-width="none" data-height="none" data-type="text"
                         data-textAlign="['center','center','center','center']"
                         data-responsive_offset="on"
                         data-beforeafter="before"
                         data-transform_idle="o:1;" data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                         data-start="1200" data-splitin="none" data-splitout="none">
                        <h1 className="text-white main-font font-weight-600 text-capitalize font-40">Ara Consultoría y Soluciones</h1>
                    </div>
                   
                    <div className="tp-caption tp-resizeme"
                         data-x="['right','center','center','center']" data-hoffset="['0','0','0','0']"
                         data-y="['middle','middle','middle','middle']" data-voffset="['20','20','30','30']"
                         data-width="none" data-height="none" data-type="text"
                         data-textAlign="['center','center','center','center']"
                         data-responsive_offset="on"
                         data-beforeafter="before"
                         data-transform_idle="o:1;" data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                         data-start="1200" data-splitin="none" data-splitout="none">
                        <h1 className="text-white main-font font-weight-600 text-capitalize font-40"><span className="font-weight-200">Empresa de Desarrollo</span> <span>Mazatlán Sin. MX.</span>
                        </h1>
                    </div>
                   
                    <div className="tp-caption tp-resizeme"
                         data-x="['right','center','center','center']" data-hoffset="['0','0','0','0']"
                         data-y="['middle','middle','middle','middle']" data-voffset="['95','95','100','100']"
                         data-width="none" data-height="none" data-type="text"
                         data-textAlign="['right','center','center','center']"
                         data-responsive_offset="on"
                         data-beforeafter="before"
                         data-transform_idle="o:1;"
                         data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;"
                         data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                         data-start="1000" data-splitin="none" data-splitout="none">
                        <p className="text-white alt-font font-18">Lider en soluciones, desarrollo sustentable y mantenible.</p>
                    </div>
                  
                    <div className="tp-caption tp-resizeme"
                         data-x="['right','center','center','center']" data-hoffset="['0','0','0','0']"
                         data-y="['middle','middle','middle','middle']" data-voffset="['180','180','175','190']"
                         data-width="['500','500','500','500']" data-textalign="['right','center','center','center']" data-type="text"
                         data-frames='[{"delay":600,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'>
                        <a href="#about" className="btn btn-medium btn-rounded btn-trans scroll">Ver Mas...</a>
                    </div>
                </li>

               

               
               
              
            </ul>
        </div>
    </div>
        <ul className="social-icons social-icons-simple revicon white d-none d-md-block d-lg-block">
            <li className="d-table"><a href="https://www.facebook.com/araconsultoriaydesarrollos" target="_blank" className="social-icon"><i className="fab fa-facebook-f"></i></a> </li>
           
        </ul>
    </div>
    );

}