import React from 'react';
import { ContactComponent } from './contact';
import { FormCotact } from './formContact';
export const HeaderComponent = () => {
    return (
        <header>
  
    <nav className="navbar navbar-top-default navbar-expand-lg navbar-simple nav-line">
        <div className="container">
            <a href="index-corporate-agency.html" title="Logo" className="logo scroll">
                
                <img src='assets/corporate-agency/images/logo.png' alt="logo" className="ml-lg-3 m-0" />
            </a>

           
            <div className="collapse navbar-collapse" id="megaone">
                <div className="navbar-nav ml-auto">
                    <a className="nav-link scroll line" href="#slider-section">Inicio</a>
                    <a className="nav-link scroll line" href="#about">Acerca de</a>
                    <a className="nav-link scroll line" href="#portfolio">Portafolio</a>
                    {/* <a className="nav-link scroll line" href="#pricing">Pricing Plans</a> */}
                    <a className="nav-link scroll line" href="#blog">Clientes</a>
                    <a href="#contact" className="btn btn-large btn-rounded btn-pink nav-button nav-link scroll line" >Contactar</a>
                </div>
            </div>
        </div>

      
        <div className="navigation-toggle">
            <ul className="slider-social toggle-btn my-0">
                <li>
                    <a  className="sidemenu_btn" id="sidemenu_toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>

    
    <div className="side-menu hidden">

        <div className="mega-title" id="mega-title"><h2 className="inner-mega-title">ARA</h2></div>

        <span id="btn_sideNavClose">
          <i className="las la-times btn-close"></i>
        </span>
        <div className="inner-wrapper">
            <nav className="side-nav w-100">
                <a href="index-corporate-agency.html" title="Logo" className="logo scroll navbar-brand">
                    <img src="assets/corporate-agency/images/logo.png" alt="logo" />
                </a>
                <ul className="navbar-nav text-capitalize">
                    <li className="nav-item">
                        <a className="nav-link scroll" href="#slider-section">Inicio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link scroll" href="#about">Acerca de</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link scroll" href="#portfolio">Porfolio</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link scroll" href="#pricing">Pricing Plans</a>
                    </li> */}
                    <li className="nav-item">
                        <a className="nav-link scroll" href="#blog">Clientes</a>
                    </li>
                    <li className="get-started-btn">
                        <a className="nav-link scroll" href="#contact">Contactar</a>
                    </li>
                </ul>
            </nav>

            <div className="side-footer w-100">
                <ul className="social-icons-simple">
                    <li><a className="social-icon wow fadeInLeft"  data-wow-delay="300ms"><i className="fab fa-facebook-f"></i> </a> </li>
                    <li><a className="social-icon wow fadeInUp"  data-wow-delay="500ms"><i className="fab fa-instagram"></i> </a> </li>
                    <li><a className="social-icon wow fadeInRight"  data-wow-delay="300ms"><i className="fab fa-twitter"></i> </a> </li>
                </ul>
                <p>&copy; 2021 ARA consultoria y Soluciones</p>
            </div>
        </div>
    </div>
    <a id="close_side_menu" ></a>
   
    <div className="quote-content hidden animated-modal" id="animatedModal">
      
        <div className="pb-md-5 p-0 text-center">
            <span className="text-pink font-weight-200 font-20">Somos Ara Consultoría y Soluciones</span>
            <h2 className="main-font font-weight-600 text-white mt-2">vamos a empezar <span className="text-pink js-rotating">Proyectos, Pagínas web</span></h2>
        </div>
       
        <FormCotact />
    </div>
</header>
    );
}